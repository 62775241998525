<template>
  <!-- page5 -->
  <div class="width-100 height-100" id="card1">
    <audio :src="audiosrc" ref="audio" preload="auto"></audio>
    <div class="yxh none">
      <img src="../../assets/img/90s/yxh2.gif" alt="">
    </div>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">前十大股东</div>
      <div class="company_name">{{ Page8Data[0].company_name }}</div>
    </div>
    <bar-chart-horizen
      ref="refBarChart1"
      id="refBarChart1"
      customClass="c_bar1"
    >
    </bar-chart-horizen>
    <div class="txt none">控股股东</div>
    <div class="arrow none">
      <img src="../../assets/img/90s/arrow.png" alt="" />
    </div>
    <svg width="300" height="300" class="svg_gradient">
      <defs>
        <radialGradient
          id="radial1"
          fx="100%"
          fy="50%"
          cx="50%"
          cy="50%"
          r="100%"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0%" stop-color="#2264EB" stop-opacity="1" />
          <stop offset="100%" stop-color="#99AAFF" stop-opacity="1" />
        </radialGradient>
      </defs>
    </svg>
  </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import barChartHorizen from "../../components/barChartHorizen/index_v1.0.vue";
import { Page8Data } from "./data";
export default {
  name: "TwoComponent",
  data: function () {
    return {
      audiosrc: "",
      market1: [],
      Page8Data:[],
    };
  },
  props: ["cardData","secData", "tempAduioArr"], 
  created() {
    // this.getdata(this.secData[0]);
  },
  computed: {},
  components: { barChartHorizen },
  mounted() {
    // this.loadChart1();
    // this.loadChart2();
  },
  watch: {
        cardData: {
      handler(val) {
        if (val.length > 0) {
          this.Page8Data = val[6];
          this.market1 = this.Page8Data[0].market1;
        }
      },
      
      deep: true,
      immediate: true,
    },
  },
  methods: {    
    async getdata(options){
      this.Page8Data = await Page8Data(options);
      this.market1 = this.Page8Data[0].market1;
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 10,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    playIn1(refName) {
      this.$refs[refName].BarChart(this.market1, {
        x: (d) => d.value,
        y: (d) => d.label,
        width: 1588,
        height: 706,
        marginLeft: 900,
        marginRight: 80,
        duration: 400,
        delay: 400,
        yPadding: 0.2,
        ease: "easeBack",
        numberSuffix: "%",
        numberPosition: "inside",
      });
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[7].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => { 
        $("#card1").fadeOut(300);
      }, (this.getTime().pageLen-0.3) * 1000);

      setTimeout(() => { 
        this.$emit("playEnd");
      }, this.getTime().pageLen* 1000);
      
      await this.playPage1();
    },
    async outPage(){
      $("#card1").fadeOut(300)
    },
    getTime() {
      // 默认返回5秒
      let time = 7;
      let audioTime = Math.ceil(this.tempAduioArr[7].audio_length);
      if (audioTime > 7) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[7].audio_link,
        pageLen: time+0.3,
      };
    },
    async playPage1() {
      $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__fadeInDownBig");
      await sleep(1000);
      this.playIn1("refBarChart1");
      await sleep(2000);
      $(".txt").removeClass("none");
      $(".txt").addClass("animate__animated animate__fadeIn");
      $(".arrow").removeClass("none");
      $(".arrow").addClass("animate__animated animate__fadeInDown");
      await sleep(2000)
       $(".yxh").removeClass("none");

    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  // background: url("../../assets/img/90s/bg.png");
  // box-sizing: border-box;
  .none {
    display: none;
  }
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 260px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 208px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .c_bar1 {
    position: absolute;
    left: 80px;
    top: 256px;
    width: 1588px;
    height: 680px;
    margin-bottom: 20px;
    /deep/ .y_label {
      font-size: 28px;
    }
    /deep/ .axis_x {
      font-size: 24px;
      .tick_line,
      .domain {
        display: none;
      }
      .text {
        fill: #ffffff;
      }
    }
    /deep/ .axis_y {
      font-size: 28px;
      .tick_line,
      .domain {
        display: none;
      }
      .text {
        fill: #fff;
      }
    }
    /deep/ .bar_group rect {
      fill: url(#radial1);
    }
    /deep/ .numbers {
      fill: #fff;
      font-size: 28px;
    }
    /deep/ .numbers:nth-of-type(5),
    /deep/ .numbers:nth-of-type(6),
    /deep/ .numbers:nth-of-type(7),
    /deep/ .numbers:nth-of-type(8) {
      text-anchor: start;
      x:985;
    }
     /deep/ .numbers:nth-of-type(5){
      x:985 !important;
    }
  }
  .txt {
    width: 240px;
    height: 84px;
    font-size: 60px;
    font-family: Helvetica;
    font-weight: bold;
    color: #ff8d1a;
    position: absolute;
    top: 157px;
    left: 824px;
  }
  .arrow {
    position: absolute;
    top: 194px;
    left: 777px;
  }
  .yxh {
        width: 20%;
    height: 43%;
    position: absolute;
    right: -3%;
    bottom: -4%;
    z-index: 9999;
  }
}
</style>


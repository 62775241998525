import axios from "axios";
import ApiServe from "@/api/index";
let tempHistory = {}; //某只股票的，或者某两只股票的数据临时存储，避免每次调接口很慢

export function Page1Data (){
  return new Promise(async (resolve) => {
    const Page1Data= [{
      videoSrc: "video/90s3.mov"
    }]    
    resolve(Page1Data);
  })}

export function Page14Data (){
  return new Promise(async (resolve) => {
    const Page14Data= [{
      videoSrc: "video/endvideo90s.mov"
    }]    
    resolve(Page14Data);
  })}
//page2
// export function Page2Data(newIpPort,options) {
//   return new Promise(async (resolve) => {
//     let fullData = tempHistory[options.sec_code+'公司介绍']   
//     let fullData2 = tempHistory[options.sec_code+'LOGO']      
//     let fullData3 = tempHistory[options.sec_code+'INTRO']
//     //公司介绍 
//     if (!fullData){
//       // fullData = await ajaxCompanyIntro(options.sec_code);
//       let params1 = {"sDtSecCode": options.sec_code,}
//       fullData =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=base&f=getCompanyIntro&req=GetCompanyIntroReq&rsp=GetCompanyIntroRsp',params1);
//       fullData = JSON.parse(fullData.content);
//       tempHistory[options.sec_code+'公司介绍'] = fullData;
//     }
//     let data = fullData.stCompanyIntro.stCompanyIntroduction;
//     let listing = data.sListingDate;
//     let jys='';    
//     if (options.sec_code.slice(0, 4) == "0001"){
//       jys = "深圳证券交易所"
//     }else if((options.sec_code.slice(0, 4) == "0101")||(options.sec_code.slice(0, 4) == "0107")){
//       jys = "上海证券交易所"
//     }
//     let address = data.sRegion;
//     //公司logo
//     if (!fullData2){
//       fullData2 = await ajaxLogo(options.sec_code); 
//       // let params2 = {"sec_code": options.sec_code.slice(4),}
//       // fullData2 =await ApiServe.dtpostapiproxyPc(newIpPort,'https://dyvideotest.wedengta.com/api/pageInfo',params2);
//       fullData2 = fullData2.data.data;
//       tempHistory[options.sec_code+'LOGO'] = fullData2;
//     }
//     let src = fullData2[0].PictureURL;
//     //公司一句话介绍
//     if (!fullData3){
//       fullData3 = await ajaxIntro(options.sec_code.slice(4)); 
//       // let params2 = {"sec_code": options.sec_code.slice(4),}
//       // fullData2 =await ApiServe.dtpostapiproxyPc(newIpPort,'https://dyvideotest.wedengta.com/api/pageInfo',params2);
//       fullData3 = fullData3.data.data;
//       tempHistory[options.sec_code+'INTRO'] = fullData3;
//     }
//     let intro = fullData3.COM_SHORT_INTRO
//     let page2Data =[]
//     //page2的数据
//     const Page2Data = [{
//           company_name: options.sec_name,          
//           company_name2:options.sec_name+' '+options.sec_code.slice(4),
//           intro:intro
//       },
//       {
//           time: listing,
//           jys: jys,
//           address: address
//       },
//       {
//           src:src
//       }
//     ]
//     console.log(Page2Data);
//     resolve(Page2Data);
//   })
// }
//page2
export function Page2Data(newIpPort,options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'公司介绍']   
    let fullData2 = tempHistory[options.sec_code+'公司图片']
    //公司介绍 
    if (!fullData){
      // fullData = await ajaxCompanyIntro(options.sec_code);
      let params1 = {"sDtSecCode": options.sec_code,}
      fullData =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=base&f=getCompanyIntro&req=GetCompanyIntroReq&rsp=GetCompanyIntroRsp',params1);
      fullData = JSON.parse(fullData.content);
      tempHistory[options.sec_code+'公司介绍'] = fullData;
    }
    let data = fullData.stCompanyIntro.stCompanyIntroduction;
    let listing = data.sListingDate;
    let jys='';    
    if (options.sec_code.slice(0, 4) == "0001"){
      jys = "深圳证券交易所"
    }else if((options.sec_code.slice(0, 4) == "0101")||(options.sec_code.slice(0, 4) == "0107")){
      jys = "上海证券交易所"
    }
    let address = data.sRegion;
    //公司图片
    if (!fullData2){
      fullData2 = await ajaxPage(options.sec_code.slice(4)); 
      // let params2 = {"sec_code": options.sec_code.slice(4),}
      // fullData2 =await ApiServe.dtpostapiproxyPc(newIpPort,'https://dyvideotest.wedengta.com/api/pageInfo',params2);
      fullData2 = fullData2.data.data;
      tempHistory[options.sec_code+'公司图片'] = fullData2;
    }
    let src = fullData2.logo_url
    let src1 = fullData2.p1_url;
    let src2 = fullData2.p2_url;
    let src3 = fullData2.p3_url;
    let intro = fullData2.introduction
    let page2Data =[]
    //page2的数据
    const Page2Data = [{
          company_name: options.sec_name,          
          company_name2:options.sec_name+' '+options.sec_code.slice(4),
          intro:intro
      },
      {
          time: listing,
          jys: jys,
          address: address
      },
      {
          src:src,
          src1: src1,
          src2: src2,
          src3: src3
      }
    ]
    // console.log(Page2Data);
    resolve(Page2Data);
  })
}
//page3
export function Page3Data(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'股东人数']  
    //公司介绍 
    if (!fullData){
      fullData = await ajaxCapitalStructure(options.sec_code);
      fullData = JSON.parse(fullData.data.content);
      tempHistory[options.sec_code+'股东人数'] = fullData;
    }
    let data = fullData.vtSExecutive.slice(0,4);
    let Page3Data=[];

    data.map((item,index)=>{
      Page3Data.push({
        name:item.sName,
        position: item.iAge+'岁/'+item.sBusiness.split("、")[0]
      })
    })
    Page3Data.unshift({
      company_name: options.sec_name
    });
    // console.log(Page3Data);
    resolve(Page3Data);
  })
}
//page4
export function Page4Data(newIpPort,options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'员工人均薪酬']  
    //员工人均薪酬
    if (!fullData){
      // fullData = await ajaxStaffStability(options.sec_code);
      let params1 = {"sSecDtCode": options.sec_code,}
      fullData =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=StaffAnalyze&f=getStaffStabilityData&req=StaffStabilityReq&rsp=StaffStabilityRsp',params1);
      fullData = JSON.parse(fullData.content); 
      tempHistory[options.sec_code+'员工人均薪酬'] = fullData;
    }
    let data = fullData.mCommStaffSalaryAveData;
    let Page4Data=[];
    let x=[];
    let y=[];
    let z=[];
    let comTotal=[];
    for(var key in data){
      x.push(key.slice(0,4)+'/'+key.slice(5,7))
      y.push((data[key].fSalaryAve/10000).toFixed(2))
      z.push(data[key].iIndustryRank)
      comTotal.push(data[key].iCompanyTotalCount)
    }

    let fullData2 = tempHistory[options.sec_code+'员工学历构成']  
    //员工学历构成
    if (!fullData2){
      // fullData2 = await ajaxStaffStruct(options.sec_code);
      let params2 = {"sSecDtCode": options.sec_code,}
      fullData2 =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=StaffAnalyze&f=getStaffStructData&req=StaffStructReq&rsp=StaffStructRsp',params2);
      
      fullData2 = JSON.parse(fullData2.content); 
      tempHistory[options.sec_code+'员工学历构成'] = fullData2;
    }
    let data2 = fullData2.mStaffEducationRatioData;
    let k=[];
    let chart2Data=[];
    for(var key2 in data2){
      k.push(data2[key2])
    }
    if (k.length == 0){
      resolve(Page4Data);
    }
    let data3 = k[k.length-1]
    let typeData ={1:'博士',2:'硕士',3:'本科',4:'大专',5:'大专以下'}
    for(var key3 in data3){
      chart2Data.push({        
        name: typeData[key3],
        value: 1*data3[key3].fRatio.toFixed(2)
      })
    }
    
    chart2Data.sort((a,b)=>{
      return b.value-a.value
    })
    Page4Data = [{
      company_name: options.sec_name,
      perIncome: y[y.length-1],
      rank: z[z.length-1],
      comTotal:comTotal[comTotal.length-1],
      chart1Data: {
          x: x,
          y: y
      },
      chart2Data:chart2Data
    }, ]
    // console.log(Page4Data);
    resolve(Page4Data);
  })
}
//page5
// export function Page5Data(newIpPort,options) {
//   return new Promise(async (resolve) => {
//     let fullData = tempHistory[options.sec_code+'营业收入']  
//     //营业收入
//     if (!fullData){
//       // fullData = await ajaxWorkEfficiency(options.sec_code);
//       // fullData = JSON.parse(fullData.data.content);
      
//       let params1 = {"sSecDtCode": options.sec_code,}
//       fullData =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=StaffAnalyze&f=getWorkEfficiencyData&req=WorkEfficiencyReq&rsp=WorkEfficiencyRsp',params1);
//       fullData = JSON.parse(fullData.content); 
//       console.log(fullData); 
//       tempHistory[options.sec_code+'营业收入'] = fullData;
//     }
//     let data = fullData.mIncomeData;
//     let allPositive=[];
//     for(var key in data){
//       allPositive.push({
//         letter: key.slice(0,4)+'年报',
//         frequency: 1*(data[key]/100000000).toFixed(2)
//       })
//     }
//     let lastIncome = allPositive[allPositive.length-1].frequency
//     let nextIncome = allPositive[allPositive.length-2].frequency
//     let ratio = ((lastIncome-nextIncome)/nextIncome*100).toFixed(2)
//     let updown
//     if ((lastIncome-nextIncome)>0){
//       updown = '增长'
//     }else if ((lastIncome-nextIncome)<0){
//       updown = '下降'
//     }
//     let Page5Data=[];
//     Page5Data = [{
//       company_name: options.sec_name,
//       income: lastIncome,
//       updown:updown,
//       ratio: ratio,
//       allPositive: allPositive,
//     },]
//     console.log(Page5Data);
//     resolve(Page5Data);
//   })
// }
// page5
export function Page5Data(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'营业收入']  
    //净利润
    if (!fullData){
      fullData = await ajaxNetProfit(options.sec_code);
      fullData = fullData.data.data;
      tempHistory[options.sec_code+'营业收入'] = fullData;
    }
    let data = fullData;
    let allPositive=[];
    data.sort((a,b)=>{
      return 1*a.yeardate-1*b.yeardate
    })
    data.map((item,index)=>{
      allPositive.push({
        letter: item.EndDate.slice(0,4)+'年报',
        frequency: 1*(item.operate_revenue/100000000).toFixed(2)
      })
    })
    let lastRevenue = allPositive[allPositive.length-1].frequency
    let nextRevenue = allPositive[allPositive.length-2].frequency
    let ratio = ((lastRevenue-nextRevenue)/nextRevenue*100).toFixed(2)
    let updown
    if ((lastRevenue-nextRevenue)>0){
      updown = '增长'
    }else if ((lastRevenue-nextRevenue)<0){
      updown = '下降'
    }
    let Page5Data=[];
    Page5Data = [{
      company_name: options.sec_name,
      income: lastRevenue.toFixed(2),
      updown:updown,
      ratio: Math.abs(ratio),
      allPositive: allPositive,
  },]
    // console.log(Page5Data);
    resolve(Page5Data);
  })
}
//page6
export function Page6Data(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'净利润']  
    //净利润
    if (!fullData){
      fullData = await ajaxNetProfit(options.sec_code);
      fullData = fullData.data.data;
      tempHistory[options.sec_code+'净利润'] = fullData;
    }
    
    let data = fullData;
    let xData=[];
    let yData=[];
    data.sort((a,b)=>{
      return 1*a.yeardate-1*b.yeardate
    })
    data.map((item,index)=>{
      xData.push(item.EndDate.slice(0,4)+'年报')
      yData.push(item.net_profit/100000000)
    })
    let lastProfit = yData[yData.length-1]
    let nextProfit = yData[yData.length-2]
    let ratio = ((lastProfit-nextProfit)/nextProfit*100).toFixed(2)
    let updown
    if ((lastProfit-nextProfit)>0){
      updown = '增长'
    }else if ((lastProfit-nextProfit)<0){
      updown = '下降'
    }
    let Page6Data=[];
    Page6Data = [{
      company_name: options.sec_name,
      profit: lastProfit.toFixed(2),
      updown:updown,
      ratio: Math.abs(ratio),
      xData:xData,
      yData:yData
  },]
    // console.log(Page6Data);
    resolve(Page6Data);
  })
}

// //page6
// export function Page6Data(options) {
//   return new Promise(async (resolve) => {
//     let fullData = tempHistory[options.sec_code+'财务']  
//     //财务
//     if (!fullData){
//       fullData = await ajaxGetFinance(options.sec_code);
//       fullData = JSON.parse(fullData.data.content);
//       tempHistory[options.sec_code+'财务'] = fullData;
//     }
//     let data = fullData.stFinancePerformance;
//     let xData=[];
//     let yData=[];
//     let eProfitUnit= data.eProfitUnit
//     let fNetProfit
//     data.vProfit.map((item,index)=>{
//       if (eProfitUnit == 2){
//         fNetProfit = (item.fNetProfit/10000).toFixed(2)
//       } else {
//         fNetProfit = (item.fNetProfit).toFixed(2)
//       }
//       xData.push(item.sYear+'年报')
//       yData.push(fNetProfit)
//     })
//     let lastProfit = yData[0]
//     let nextProfit = yData[1]
//     let ratio = ((lastProfit-nextProfit)/nextProfit*100).toFixed(2)
//     let updown
//     if ((lastProfit-nextProfit)>0){
//       updown = '增长'
//     }else if ((lastProfit-nextProfit)<0){
//       updown = '下降'
//     }
//     let Page6Data=[];
//     Page6Data = [{
//       company_name: options.sec_name,
//       profit: lastProfit,
//       updown:updown,
//       ratio: ratio,
//       xData:xData.reverse(),
//       yData:yData.reverse()
//   },]
//     console.log(Page6Data);
//     resolve(Page6Data);
//   })
// }
//page7
export function Page7Data(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'公司介绍']  
    //公司介绍
    if (!fullData){
      // fullData = await ajaxCompanyIntro(options.sec_code);
      // fullData = JSON.parse(fullData.data.content);
      
      let params1 = {"sDtSecCode": options.sec_code,}
      fullData =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=base&f=getCompanyIntro&req=GetCompanyIntroReq&rsp=GetCompanyIntroRsp',params1);
      fullData = JSON.parse(fullData.content);
      tempHistory[options.sec_code+'公司介绍'] = fullData;
    }
    let data = fullData.stCompanyIntro.vMainBusinessByProduction[0];
    let reportType = data.sYear+'年报'
    let population2=[];
    data.vItem.map((item,index)=>{
      population2.push({
        name: item.sProductName,
        value: 1*(item.stOpeRev.dOrgValue/100000000).toFixed(2)
      })
    })
    let Page7Data=[];
    Page7Data = [{
      company_name: options.sec_name,
      reportType:reportType,
      population2: population2.slice(0,4),
    }]
    // console.log(Page7Data);
    // console.log(`Page7Data`);
    resolve(Page7Data);
  })
}


//page8
export function Page8Data(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'股东人数']  
    //投资股东
    if (!fullData){
      fullData = await ajaxCapitalStructure(options.sec_code);
      fullData = JSON.parse(fullData.data.content);
      tempHistory[options.sec_code+'股东人数'] = fullData;
    }
    let data = fullData.vTopHolder;
    let market1=[];
    data.map((item,index)=>{
      market1.push({
        label: item.sName,
        value: 1*(item.sRatio.replace('%',''))
      })
    })
    // console.log(market1);
    // market1.sort((a,b)=>{
    //   return b.value-a.value
    // })
    let Page8Data=[];
    Page8Data = [{
      company_name: options.sec_name,
      market1: market1,
    }]
    // console.log(Page8Data);
    resolve(Page8Data);
  })
}


//page9
export function Page9Data(options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'股东人数']  
    //营业收入
    if (!fullData){
      fullData = await ajaxCapitalStructure(options.sec_code);
      fullData = JSON.parse(fullData.data.content);
      tempHistory[options.sec_code+'股东人数'] = fullData;
    }
    let data = fullData.stCapitalStructure.vCapitalStructureDetail;
    let allPositive=[];
    data.map((item,index)=>{
      if(["03-31","06-30","09-30","12-31"].indexOf(item.sDate.slice(5,11)) > -1 && item.iShareholderNumber != 0){
        allPositive.push({
          letter:item.sDate.slice(0,4)+'/'+item.sDate.slice(5,7),
          frequency:(item.iShareholderNumber/10000)
        })
      }
    })
    let lastIncome = allPositive[0].frequency
    let nextIncome = allPositive[1].frequency
    let ratio = ((lastIncome-nextIncome)/nextIncome*100).toFixed(2)
    let updown
    if ((lastIncome-nextIncome)>0){
      updown = '增长'
    }else if ((lastIncome-nextIncome)<0){
      updown = '下降'
    }
    let Page9Data=[];
    Page9Data = [{
      company_name: options.sec_name,
      profit: lastIncome,
      updown:updown,
      ratio: Math.abs(ratio),
      allPositive: allPositive.slice(0,5).reverse(),
    }
    ]
    // console.log(Page9Data);
    resolve(Page9Data);
  })
}

//page10
export function Page10Data(newIpPort,options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'财报解析']  
    //财报解析
    if (!fullData){
      // fullData = await ajaxFinancialAnalysis(options.sec_code);
      // fullData = JSON.parse(fullData.data.content);
      let params1 = {"sDtSecCode": options.sec_code,"vSeasonType": [1, 2, 3, 4]}
      fullData =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=base&f=getFinanceAnalysis&req=GetFinanceAnalysisReq&rsp=GetFinanceAnalysisRsp',params1);
      fullData = JSON.parse(fullData.content);
      tempHistory[options.sec_code+'财报解析'] = fullData;
    }
    let data = fullData.mvFinAnalysisDimItem["1"][0].vFinAnalysisIndexItem[2].vFinAnalysisSubIndexItem[0];
    let valueAvg = data.sOrgValueAvg;
    let xData=[];
    let y1Data=[];
    let y2Data=[];
    data.vFinAnalysisDataItem.map((item,index)=>{
      xData.push(item.sYear)
      y1Data.push(item.sOrgValue)
      y2Data.push(item.sOrgValueYoY)
    })


    let Page10Data=[];
    Page10Data = [{
      company_name: options.sec_name,
      valueAvg:valueAvg,
      xData:xData.reverse(),
      y1Data:y1Data.reverse(),
      y2Data:y2Data.reverse(),
      }
    ]
    // console.log(Page10Data);
    resolve(Page10Data);
  })
}
//page11
export function Page11Data(newIpPort,options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'财报解析']  
    //财报解析
    if (!fullData){
      // fullData = await ajaxFinancialAnalysis(options.sec_code);
      // fullData = JSON.parse(fullData.data.content);      
      let params1 = {"sDtSecCode": options.sec_code,"vSeasonType": [1, 2, 3, 4]}
      fullData =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=base&f=getFinanceAnalysis&req=GetFinanceAnalysisReq&rsp=GetFinanceAnalysisRsp',params1);
      fullData = JSON.parse(fullData.content);
      tempHistory[options.sec_code+'财报解析'] = fullData;
    }
    let data = fullData.mvFinAnalysisDimItem["1"][1].vFinAnalysisIndexItem[3].vFinAnalysisSubIndexItem[0];
    let valueAvg = data.sOrgValueAvg;
    let xData=[];
    let y1Data=[];
    let y2Data=[];
    data.vFinAnalysisDataItem.map((item,index)=>{
      xData.push(item.sYear)
      y1Data.push(item.sOrgValue)
      y2Data.push(item.sOrgValueYoY)
    })


    let Page11Data=[];
    Page11Data = [{
      company_name: options.sec_name,
      valueAvg:valueAvg,
      xData:xData.reverse(),
      y1Data:y1Data.reverse(),
      y2Data:y2Data.reverse(),
      }
    ]
    // console.log(Page11Data);
    resolve(Page11Data);
  })
}
//page12
export function Page12Data(newIpPort,options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'财报解析']  
    //财报解析
    if (!fullData){
      // fullData = await ajaxFinancialAnalysis(options.sec_code);
      // fullData = JSON.parse(fullData.data.content);          
      let params1 = {"sDtSecCode": options.sec_code,"vSeasonType": [1, 2, 3, 4]}
      fullData =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=base&f=getFinanceAnalysis&req=GetFinanceAnalysisReq&rsp=GetFinanceAnalysisRsp',params1);
      fullData = JSON.parse(fullData.content);
      tempHistory[options.sec_code+'财报解析'] = fullData;
    }
    let data = fullData.mvFinAnalysisDimItem["1"][2].vFinAnalysisIndexItem[1].vFinAnalysisSubIndexItem[0];
    // console.log(data);
    let valueAvg = data.sOrgValueAvg;
    let xData=[];
    let y1Data=[];
    let y2Data=[];
    data.vFinAnalysisDataItem.map((item,index)=>{
      xData.push(item.sYear)
      y1Data.push(item.sOrgValue)
      y2Data.push(item.sOrgValueYoY)
    })


    let Page12Data=[];
    Page12Data = [{
      company_name: options.sec_name,
      valueAvg:valueAvg,
      xData:xData.reverse(),
      y1Data:y1Data.reverse(),
      y2Data:y2Data.reverse(),
      }
    ]
    // console.log(Page12Data);
    resolve(Page12Data);
  })
}

//page13
export function Page13Data(newIpPort,options) {
  return new Promise(async (resolve) => {
    let fullData = tempHistory[options.sec_code+'财报等级']  
    //财报解析
    if (!fullData){
      // fullData = await ajaxStockMineSweepInfo(options.sec_code);
      // fullData = fullData.data;
              
      let params1 = {"sDtSecCode": options.sec_code,}
      fullData =await ApiServe.dtpostapiproxyPc(newIpPort,'https://comm.wedengta.com/?s=commredis&f=getStockMineSweepInfo&req=GetStockMineSweepInfoReq&rsp=GetStockMineSweepRedisRsp&commredis=0',params1);
      fullData = JSON.parse(fullData.content);
      tempHistory[options.sec_code+'财报等级'] = fullData;
    }
    let data = fullData.stFinanceRisk;
    let secode = options.sec_name+' '+options.sec_code.slice(4)
    let score = data.iScore
    let riskLevel = data.sRiskLevel
    let riskLevelDesc = data.sRiskLevelDesc
    let Page13Data=[];
    Page13Data = [{
      company_name: options.sec_name,
      secode:secode,
      score:score,
      riskLevel:riskLevel,
      riskLevelDesc:riskLevelDesc,
      }
    ]
    // console.log(Page13Data);
    resolve(Page13Data);
  })
}


// 公司简介
async function ajaxCompanyIntro(options){
  return await axios.post("https://comm.wedengta.com/?s=base&f=getCompanyIntro&req=GetCompanyIntroReq&rsp=GetCompanyIntroRsp",
  JSON.stringify({
      sDtSecCode: options,
  }))
}

// 公司图片
async function ajaxPage(options){
  return await axios.get("https://dyvideotest.wedengta.com/api/pageInfo?sec_code="+options)
}

// 净利润
async function ajaxNetProfit(options){
  return await axios.get("https://dyvideotest.wedengta.com/api/netProfit?sec_code="+options)
}

// LOGO
async function ajaxLogo(options){
  return await axios.get("https://dyvideotest.wedengta.com/api/logoInfo?sec_code="+options)
}

// 一句话介绍
async function ajaxIntro(options){
  return await axios.get("https://dyvideotest.wedengta.com/api/introInfo?sec_code="+options)
}

//普通员工人均薪酬
async function ajaxStaffStability(options){
  return await axios.post("https://comm.wedengta.com/?s=StaffAnalyze&f=getStaffStabilityData&req=StaffStabilityReq&rsp=StaffStabilityRsp",
  JSON.stringify({
    sSecDtCode: options,
  }))
}

//员工学历构成
async function ajaxStaffStruct(options){
  return await axios.post("https://comm.wedengta.com/?s=StaffAnalyze&f=getStaffStructData&req=StaffStructReq&rsp=StaffStructRsp",
  JSON.stringify({
    sSecDtCode: options,
  }))
}

//营业收入
async function ajaxWorkEfficiency(options){
  return await axios.post("https://comm.wedengta.com/?s=StaffAnalyze&f=getWorkEfficiencyData&req=WorkEfficiencyReq&rsp=WorkEfficiencyRsp",
  JSON.stringify({
    sSecDtCode: options,
  }))
}
//获取财务
async function ajaxGetFinance(options){
  return await axios.post("https://comm.wedengta.com/?s=base&f=getFinance&req=FinanceReq&rsp=FinanceRsp",
  JSON.stringify({
    sDtSecCode: options,
  }))
}
//获取财报解析
async function ajaxFinancialAnalysis(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=base&f=getFinanceAnalysis&req=GetFinanceAnalysisReq&rsp=GetFinanceAnalysisRsp",
    JSON.stringify({
      sDtSecCode: options,
      vSeasonType: [1, 2, 3, 4]
    })
  );
}
//投资股东
async function ajaxSecurityDetails(options) {
  return await axios.post(
    "http://comm.wedengta.com/?s=investGraph&f=getSecurityDetails&req=SecurityDetailReq&rsp=SecurityDetailRsp",
    JSON.stringify({
      sDtSecCode: options,
      iVersion: 1
    })
  );
}
//股本结构 股东人数
async function ajaxCapitalStructure(options) {
  return await axios.get(
    "https://sec.wedengta.com/getSecInfo?action=company&seccode="+options
  );
}
//获取财报等级
async function ajaxStockMineSweepInfo(options) {
  return await axios.post(
    "https://comm.wedengta.com/?s=commredis&f=getStockMineSweepInfo&req=GetStockMineSweepInfoReq&rsp=GetStockMineSweepRedisRsp&commredis=0&encode=1",
    JSON.stringify({
      sDtSecCode: options
    })
  );
}
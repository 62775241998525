<template>
  <!-- page5 -->
  <div class="width-100 height-100" id="card1">
    <audio :src="audiosrc" ref="audio" preload="auto"></audio>
    <div class="yxh none">
      <img src="../../assets/img/90s/yxh2.gif" alt="">
    </div>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">股东人数</div>
      <div class="company_name">{{ Page9Data[0].company_name }}</div>
    </div>
    <div class="content none">
      <div class="dec">
        <div>总股东人数为</div>
        <div class="mark"><span id="num1">{{ Page9Data[0].profit }}</span><span>万</span></div>
        <div>环比{{Page9Data[0].updown}}</div>
        <div class="mark"><span id="num2">{{ Page9Data[0].ratio }}</span><span>%</span></div>
      </div>
      <bar-chart ref="refBarChart3" id="refBarChart3" customClass="c_bar2">
      </bar-chart>
      <svg width="300" height="300" class="svg_gradient">
        <defs>
          <radialGradient
            id="radial1"
            fx="50%"
            fy="100%"
            cx="50%"
            cy="100%"
            r="100%"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0%" stop-color="#2264EB" stop-opacity="1" />
            <stop offset="100%" stop-color="#99AAFF" stop-opacity="1" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import barChart from "@/components/barChart/index_v1.0.vue";
import { Page9Data } from "./data";
export default {
  name: "TwoComponent",
  data: function () {
    return {
      audiosrc: "",
      Page9Data:[],
      allPositive: [],
    };
  },
   props: ["cardData","secData", "tempAduioArr"], 
  created() {
    // this.getdata(this.secData[0]);
  },
  computed: {},
  components: { barChart },
  mounted() {
  },
  watch: {
        cardData: {
      handler(val) {
        if (val.length > 0) {
          this.Page9Data = val[7];
          this.allPositive = this.Page9Data[0].allPositive;
        }
      },
      
      deep: true,
      immediate: true,
    },
  },
  methods: {  
    async getdata(options){
      this.Page9Data = await Page9Data(options);
      this.allPositive = this.Page9Data[0].allPositive;
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    playIn3(refName) {
      this.$refs[refName].BarChart(this.allPositive, {
        x: (d) => d.letter,
        y: (d) => d.frequency,
        width: 1407,
        height: 500,
        marginLeft: 110,
        duration: 1000,
        delay: 70,
        ease: "easeCircle",
        isShowNumber: false,
        xTickNumber: 5,
        yTickNumber: 5,
      });
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[8].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => { 
        $("#card1").fadeOut(300);
      }, (this.getTime().pageLen-0.3) * 1000);

      setTimeout(() => { 
        this.$emit("playEnd");
      }, this.getTime().pageLen* 1000);
      
      await this.playPage1();
    },
    async outPage(){
      $("#card1").fadeOut(300)
    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[8].audio_length);
      if (audioTime > 5) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[8].audio_link,
        pageLen: time+0.3,
      };
    },
    async playPage1() {
          $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__fadeInDownBig");
      await sleep(1000);
      $(".content").removeClass("none");
      $(".content").addClass("animate__animated animate__fadeIn");
      this.playNumVaryIn("#num1", 2);
      this.playNumVaryIn("#num2", 2);
      await sleep(1000);
      this.playIn3("refBarChart3");
       $(".yxh").removeClass("none");
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  // background: url("../../assets/img/90s/bg.png");
  // box-sizing: border-box;
  .none{
    display: none;
  }
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 208px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 208px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .content {
    position: absolute;
    left: 180px;
    top: 230px;
    width: 1560px;
    height: 730px;
    background: linear-gradient(180deg, #33475d 0%, #11121a 100%);
    box-shadow: 0px 10px 30px rgba(6, 19, 29, 0.6);
    border-radius: 24px;
    .dec {
      display: flex;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.89;
      position: absolute;
      top: 113px;
      left: 395px;
      height: 67px;
      line-height: 67px;
      .mark {
        font-size: 46px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff8d1a;
        opacity: 0.89;
        margin-left: 15px;
        margin-right: 82px;
        line-height: 57px;
      }
    }
    .c_bar2 {
      position: absolute;
      left: 80px;
      top: 182px;
      width: 1407px;
      height: 500px;
      margin-bottom: 20px;
      /deep/ .y_label {
        font-size: 18px;
      }
      /deep/ .axis_x {
        font-size: 24px;
        .tick_line,
        .domain {
          display: none;
        }
        .text {
          fill: #ffffff;
        }
      }
      /deep/ .axis_y {
        font-size: 18px;
        .tick_line,
        .domain {
          display: none;
        }
        .text {
          fill: #b1bbd0;
        }
        .tick_long_line {
           stroke: rgba(228, 231, 240, 0.2);
        }
      }
      /deep/ .bar_group rect {
        fill: url(#radial1);
        width: 80px;
      }
      /deep/ .bar_group rect:first-of-type {
        x: 211px;
      }
      /deep/ .bar_group rect:nth-of-type(2) {
        x: 464px;
      }
      /deep/ .bar_group rect:nth-of-type(3) {
        x: 720px;
      }
      /deep/ .bar_group rect:nth-of-type(4) {
        x: 976px;
      }
      /deep/ .bar_group rect:nth-of-type(5) {
        x: 1226px;
      }
    }
  }
  .yxh {
        width: 20%;
    height: 43%;
    position: absolute;
    right: -3%;
    bottom: -4%;
    z-index: 9999;
  }
}
</style>


<template>
  <!-- page1 -->
  <div class="width-100 height-100 content " id="card1">
    <audio :src="audiosrc" ref="audio" autoplay="autoplay" preload="auto"></audio>
    <video
      class="end_video none"
      :src="src"
      muted
      width="100%"
      height="100%"
      ref="endVideo"
      preload="auto"
    ></video>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
export default {
  name: "OneComponent",
  data: function () {
    return {
      audiosrc: "",
      audioData: {},
      src:"",
    };
  },

  props: {
    tempAduioArr: {
      type: Array,
      default: () => [],
    },
    cardData:{
      type: Array,
      default: () => [],
    }
  },
  watch: {
    tempAduioArr: {
      handler(val) {
        if (val.length > 0) {
          this.audioData = val;
        }
      },
      deep: true,
      immediate: true,
    },
    cardData: {
      handler(val) {
        if (val.length == 14) {
          this.src = val[13][0].videoSrc;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {},
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      $("#card1").fadeIn(2000)
      this.audiosrc = this.audioData[13].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });
      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);
      await this.playIn();
      await this.playPage1();
    },
    async playIn(){
      $(".end_video").removeClass("none");
      $(".end_video").addClass("animate__animated animate__fadeIn");
      await sleep(500);
    },
    async playPage1() {
      this.$refs.endVideo.play();
    },
    getTime() {
      let time = 4; 
      let audioTime = Math.ceil(this.audioData[13].audio_length);
      if (audioTime > time) {
        time = audioTime;
      }
      return {
        audio_link: this.audioData[13].audio_link,
        pageLen: time,
      };
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  background: url("../../assets/img/90s/bg.png");
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;

}
  .none {
    display: none;
  }
</style>


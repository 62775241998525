<template>
  <!-- page2 -->
  <div class="width-100 height-100 content" id="card1">
    <audio :src="audiosrc" ref="audio" preload="auto"></audio>
    <div class="yxh none">
    <img src="../../assets/img/90s/yxh2.gif" alt="">
    </div>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
    <div class="text">基本介绍</div>
      <div class="company_name" :style="activation()">{{ Page2Data[0].company_name }}</div>
    </div>  
    <div class="title none">
      <div class="logo" id ="logo">
        <!-- <div :style="`background:url(${logo})  no-repeat center`">
        </div> -->
        <img :src="logo">
      </div> 
      <div class="text none" id ="text">{{company_name}}</div>
    </div>        
    <div class="intro none" id ='intro'>{{intro}}
    </div> 
    <div class="left none">
      <div class="bar none">
        <img src="../../assets/img/90s/bar2.png" alt="" />
      </div>
        <ul>
          <li>
            <div class="top">上市时间</div>
            <div class="bottom">{{ Page2Data[1].time }}</div>
          </li>
          <li :style="activation()">
            <div class="top">所属交易所</div>
            <div class="bottom">{{ Page2Data[1].jys }}</div>
          </li>
          <li>
            <div class="top">公司地址</div>
            <div class="bottom">{{ Page2Data[1].address }}</div>
          </li>
        </ul>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import src1 from "../../assets/img/90s/pic2.png";
import src3 from "../../assets/img/90s/pic.png";
import src4 from "../../assets/img/90s/pic4.png";
// import {Page2Data} from "./data";

export default {
  name: "TwoComponent",
  props: ["cardData","secData","tempAduioArr"],
  data: function () {
    return {
      audiosrc: "",
      src1: "",
      src3: "",
      src4: "",
      time: "",
      jys: "",
      address: "",
      Page2Data: '',
      logo:"",
      company_name:"",
      intro:"",
    };
  },
  created() {
    // this.getdata(this.secData[0]);
  },  
  computed:{
    activation() {              
      return () => { // 使用JavaScript闭包，进行传值操作   交易所背景色区分
          if (this.cardData[0][1].jys == "深圳证券交易所"){
            return {'background':'linear-gradient(180deg, #C898FF 0%, #7823EC 100%)'}
          } else if (this.cardData[0][1].jys == "上海证券交易所"){
            return {'background':'linear-gradient(180deg, #98A9FF 0%, #2365EC 100%)'}
          } else if (this.cardData[0][1].jys == "北京证券交易所"){
            return {'background':'linear-gradient(180deg, #FFC68E 0%, #D86008 100%)'}
          }
                        
      }
    }
  },
  watch: {
    cardData: {
      handler(val) {
        if (val.length > 0) {
          this.Page2Data = val[0];
          this.src1 = this.Page2Data[2].src1;
          this.src3 = this.Page2Data[2].src2;
          this.src4 = this.Page2Data[2].src3;
          this.logo = this.Page2Data[2].src;
          this.company_name = this.Page2Data[0].company_name2;
          this.intro = this.Page2Data[0].intro;
        }
      },
      
      deep: true,
      immediate: true,
    },
  },
  mounted() {

  },
  methods: {
    // async getdata(options){
    //   this.Page2Data = await Page2Data(options);
      // this.src1 = this.Page2Data[2].src1;
      // this.src3 = this.Page2Data[2].src2;
      // this.src4 = this.Page2Data[2].src3;
    // },
    // 页面card一个接一个播放
    async playCardStart() {
      if (!this.tempAduioArr[1]) return;
      this.audiosrc = this.tempAduioArr[1].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => { 
        $("#card1").fadeOut(300);
      }, (this.getTime().pageLen-0.3) * 1000);

      setTimeout(() => { 
        this.$emit("playEnd");
      }, this.getTime().pageLen* 1000);
      
      await this.playPage1();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    async outPage(){
      $("#card1").fadeOut(300)
    },
    async playPage1() {
      $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__bounceInDown");
      await sleep(300);          
      $(".title").removeClass("none");
      $(".logo").removeClass("none");         
      $(".text").removeClass("none");
      this.playIn("runIn", "typeOut", "#text");
      await sleep(900);
      $(".intro").removeClass("none");
      this.playIn("runIn", "typeOut", "#intro");
      await sleep(1000);
      $(".left").removeClass("none");
      $(".left .bar").removeClass("none");
      $(".left .bar").addClass("animate__animated animate__fadeInUp");
      await sleep(500);
      $(".left li").eq(0).addClass("animate__animated animate__fadeInUpBig");
      await sleep(500);
      $(".left li").eq(1).addClass("animate__animated animate__fadeInUpBig");
      await sleep(500);
      $(".left li").eq(2).addClass("animate__animated animate__fadeInUpBig");
      $(".yxh").removeClass("none");
    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      if (!this.tempAduioArr[1]) return;
      let audioTime = Math.ceil(this.tempAduioArr[1].audio_length);
      if (audioTime > time) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[1].audio_link,
        pageLen: time+0.3,
      };
    },
  },
};
</script>
<style scoped lang="less">

@import url("../../assets/css/index.css");

#card1 {
  // background: url("../../assets/img/90s/bg.png");
  // box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 208px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 265px;
      height: 60px;
      // background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .title {
    position: absolute;
    display: flex;   
    width: 100%;  
    height: 96px;
    top :280px;  
    justify-content: center;
  // z-index: 999;
    img {
      width:auto;
      height: 96px;
      border-radius: 20px;
      background-size: contain !important;
    }
    .text{
      height: 96px;
      line-height: 72px;
      margin-left: 20px;
      font-size: 72px;
      font-weight: 800;
      color:#FFFFFF;
      display: flex;
      align-items: center;
    }
  }
  .intro{
    width: 100%; 
    height: 52px;
    margin-top :848px;
    left:758px; 
    text-align: center;
    color:#FFFFFF;
    font-size: 52px;
    line-height: 52px;
  }
  .left {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 500px;
    // width: 50%;
    display: flex;

    .bar {
      position: absolute;
      left: 466px;
      top: 448px;
      height: 450px;
      overflow-x: hidden;
      overflow-y: hidden;
      img {        
        overflow-x: hidden;
        overflow-y: hidden;
      }
    }
    ul {
      position: absolute;
      display:inline;
      top: 528px;
      left: 256px;
      height: 900px;
      overflow-x: hidden;
      overflow-y: hidden;
      li {
        opacity: 0;
        margin-left: 40px;
        margin-right: 40px;
        display: inline-block;
        color: #fff;
        width: 476px;
        height: 220px;
        padding: 26px 58px 24px 25px;
        box-sizing: border-box;
        margin-bottom: 48px;
        overflow-x: hidden;
        overflow-y: hidden;
        .top {
          // width: 150px;
          height: 28px;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.6;
          margin-bottom: 18px;
        }
        .bottom {
          // width: 328px;
          height: 62px;
          font-size: 44px;
          font-family: Helvetica;
          font-weight: bold;
          color: #ffffff;
          opacity: 0.8;
        }
      }
      li:first-of-type,
      li:nth-of-type(3) {
        width: 416px;
        height: 160px;
        background: linear-gradient(180deg, #33425b 0%, #14161e 100%);
        box-shadow: 0px 4px 20px rgba(6, 19, 29, 0.6);
        border-radius: 16px;
      }
      li:nth-of-type(2) {
        width: 416px;
        height: 160px;
        // background: linear-gradient(180deg, #98a9ff 0%, #2365ec 100%);
        box-shadow: 0px 4px 20px rgba(6, 19, 29, 0.6);
        border-radius: 16px;
      }
    }
  }
  

  .none {
    display: none;
  }
  
  .yxh {
        width: 20%;
    height: 43%;
    position: absolute;
    right: -3%;
    bottom: -4%;
    z-index: 9999;
  }
}
</style>


<template>
  <!-- page2 -->
  <div class="width-100 height-100" id="card1">
    <audio :src="audiosrc" ref="audio" preload="auto"></audio>
    <div class="yxh none">
      <img src="../../assets/img/90s/yxh2.gif" alt="">
    </div>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">员工分析</div>
      <div class="company_name">{{ Page4Data[0].company_name }}</div>
    </div>
    <div class="left box none">
      <div class="title">普通员工人均薪酬</div>
      <div class="dec none">
        <div>
          普通员工人均收入：<span id="num1" class="mark">{{
            Page4Data[0].perIncome
          }}</span
          ><span class="mark" style="margin-right: 25px">万</span>
        </div>
        <div>
          行业排名：<span class="mark" id="num2">{{ Page4Data[0].rank }}</span
          ><span class="mark">/</span><span class="mark1">{{ Page4Data[0].comTotal}}</span>
        </div>
      </div>
      <div id="chart1"></div>
    </div>
    <div class="right box none">
      <div class="title">员工学历构成</div>
      <div class="legend none">
        <ul>
          <li 
          v-for="(item,index) in chartData" 
          :key="index" 
          >
            <div class="xl">
              <div class="icon" :class='"icon"+(index+1)'></div>
              <div class="text">{{item.name}}</div>
            </div>
            <div class="proportion">{{item.value}}%
            </div>
          </li>
          <!-- <li>
            <div class="xl">
              <div class="icon icon1"></div>
              <div class="text">{{chartData[0].name}}</div>
            </div>
            <div class="proportion">{{chartData[0].value}}%</div>
          </li>
          <li>
            <div class="xl">
              <div class="icon icon2"></div>
              <div class="text">{{chartData[1].name}}</div>
            </div>
            <div class="proportion">{{chartData[1].value}}%</div>
          </li>
          <li>
            <div class="xl xl5">
              <div class="icon icon3"></div>
              <div class="text">{{chartData[2].name}}</div>
            </div>
            <div class="proportion">{{chartData[2].value}}%</div>
          </li>
          <li>
            <div class="xl">
              <div class="icon icon4"></div>
              <div class="text">{{chartData[3].name}}</div>
            </div>
            <div class="proportion">{{chartData[3].value}}%</div>
          </li>
          <li>
            <div class="xl">
              <div class="icon icon5"></div>
              <div class="text">{{chartData[4].name}}</div>
            </div>
            <div class="proportion">{{chartData[4].value}}%</div>
          </li> -->
        </ul>
      </div>
      <donut-chart
        ref="refDonutChart4"
        id="refDonutChart4"
        customClass="c_donut4"
        class="none"
      >
      </donut-chart>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import donutChart from "../../components/donutChart";
import { Page4Data } from "./data";

export default {
  name: "TwoComponent",
  data: function () {
    return {
      audiosrc: "",
      chartData: [],
      Page4Data: [],
      timeData: [],
    };
  },
  props: ["cardData","secData", "tempAduioArr"],
  computed: {},
  components: { donutChart },
  created() {
    // this.getdata(this.secData[0]);
  },
  watch: {
        cardData: {
      handler(val) {
        if (val.length > 0) {
          this.Page4Data = val[2];
          this.chartData = this.Page4Data[0].chart2Data;
          this.timeData = this.Page4Data[0].chart1Data;
        }
      },
      
      deep: true,
      immediate: true,
    },
  },

  methods: {    
    async getdata(options){
      this.Page4Data = await Page4Data(options);
      this.chartData = this.Page4Data[0].chart2Data;
      this.timeData = this.Page4Data[0].chart1Data;
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 2000,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    playIn4(refName) {
      this.$refs[refName].DonutChart(this.chartData, {
        name: (d) => d.name,
        value: (d) => d.value,
        width: 540,
        height: 540,
        donutRatio: 0.4, //donut的内圆半径占比
        labelRatio: 0.75, //label的半径占比
        padAngle: 0, //pad间隔角度
        cornerRadius: 4, //圆角
        colors: ["#F87E18", "#4671F1", "#6EB9F1", "#A751E6", "#F9DE49"],
        duration: 800,
        delay: 50,
        ease: "easeBackInOut",
        labelPosition: "outsideWithOutLine",
        valueType: "absolute",
        isSort: false,
      });
    },
    loadChart1() {
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          data: this.timeData.x,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#53668E",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#FFFFFF",
              fontSize: 28,
              fontFamily: "Helvetica",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#FFFFFF",
              fontSize: 28,
              fontFamily: "Helvetica",
              opacity: 0.5,
            },
          },
          splitLine: {
            show: true,
            interval: "auto",
            lineStyle: {
              color: ["#53668E"],
              width: 1,
            },
          },
        },
        series: [
          {
            data: this.timeData.y,
            type: "line",
            smooth: 0.6,
            showSymbol: true,
            symbolSize: 16,
            lineStyle: {
              color: "#50B3F7",
              width: 3,
            },
          },
        ],
      });
    },

    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[3].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });


      setTimeout(() => { 
        $("#card1").fadeOut(300);
      }, (this.getTime().pageLen-0.3) * 1000);

      setTimeout(() => { 
        this.$emit("playEnd");
      }, this.getTime().pageLen* 1000);
      
      await this.playPage1();
    },
    async outPage(){
      $("#card1").fadeOut(300)
      
    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[3].audio_length);
      if (audioTime > 5) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[3].audio_link,
        pageLen: time+0.3,
      };
    },
    async playPage1() {
      $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__fadeInDownBig");
      await sleep(1000);
      $(".left").removeClass("none");
      $(".left").addClass("animate__animated animate__fadeIn");
      this.playNumVaryIn("#num1", 1);
      this.playNumVaryIn("#num2", 0);
      await sleep(1000);
      this.loadChart1();
      await sleep(1000);
      $(".right").removeClass("none");
      $(".right").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      this.playIn4("refDonutChart4");
      $(".right .legend").removeClass("none");
      $(".right .legend").addClass("animate__animated animate__fadeIn");
      $(".yxh").removeClass("none");
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  // background: url("../../assets/img/90s/bg.png");
  // box-sizing: border-box;
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 208px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 208px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .box {
    width: 760px;
    height: 680px;
    background: linear-gradient(180deg, #33475d 0%, #11121a 100%);
    box-shadow: 0px 10px 30px rgba(6, 19, 29, 0.6);
    border-radius: 24px;
    .title {
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin: 5px 27px;
    }
  }
  .left {
    position: absolute;
    left: 180px;
    top: 250px;
    background: url("../../assets/img/90s/biaoti1.png") no-repeat;
    .dec {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #ffffff;
      display: flex;
      position: absolute;
      top: 142px;
      left: 50px;

      .mark {
        font-size: 40px;
        font-family: Helvetica;
        font-weight: bold;
        color: #ff8d1a;
      }
      .mark1 {
        font-size: 40px;
        font-family: Helvetica;
        font-weight: bold;
        color: #ffffff;
      }
    }
    #chart1 {
      width: 640px;
      height: 427px;
      position: absolute;
      left: 50px;
      bottom: 61px;
    }
  }
  .right {
    position: absolute;
    right: 180px;
    top: 250px;
    background: url("../../assets/img/90s/biaoti2.png") no-repeat;
    .legend {
      position: absolute;
      top: 215px;
      right: 87px;
      li {
        display: flex;
        width: 200px;
        height: 80px;
        line-height: 12px;
        justify-content: space-between;
        .xl {
          display: flex;
          width: 120px;
          justify-content: space-between;
          .icon {
            width: 17px;
            height: 17px;
            border-radius: 50%;
          }
          .text {
            width: 96px;
            height: 24px;
            color: #ffffff;
            opacity: 0.8;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }
          .icon1 {
            background-color: #f87e18;
          }
          .icon2 {
            background-color: #4671f1;
          }
          .icon3 {
            background-color: #6eb9f1;
          }
          .icon4 {
            background-color: #a751e6;
          }
          .icon5 {
            background-color: #f9de49;
          }
        }
        .xl5 {
          width: 120px;
          .text {
            width: 96px;
          }
        }
        .proportion {
          width: 48px;
          height: 24px;
          font-size: 24px;
          font-family: Helvetica;
          color: #ffffff;
        }
      }
    }
    .c_donut4 {
      position: absolute;
      top: 109px;
      left: -28px;
      font-size: 14px;
      min-height: 200px;
      margin-bottom: 30px;
      display: inline-block;
      margin-bottom: 30px;
      /deep/ .donut_txt1 {
        font-size: 26px;
      }
      /deep/ .donut_txt2 {
        font-size: 14px;
        display: none;
      }
      /deep/ .poly_lines {
        stroke-dasharray: 11px 2px;
        stroke-width: 2px;
      }
      /deep/ text:nth-of-type(4) {
        text-anchor: end !important;
      }
      /deep/ text:nth-of-type(5) {
        text-anchor: start !important;
      }
    }
  }
  
  .yxh {
        width: 20%;
    height: 43%;
    position: absolute;
    right: -3%;
    bottom: -4%;
    z-index: 9999;
  }
}
</style>

